import React from 'react';
import { Link } from 'gatsby';

function BlogArchiveItem({ post }) {
  return (
    <article className="blog-item">
      <header className="blog-item__header">
        <div className="blog-item__thumbnail">
          <p className="blog-item__date">
            {post.date}
          </p>
          {post.featured_media && (
            <img
              className="blog-item__image"
              src={post.featured_media.source_url}
              alt={post.featured_media.alt_text}
            />
          )}
        </div>
      </header>
      <div className="blog-item__body">
        <h3 className="blog-item__title">
          {post.title}
        </h3>
        <div
          className="blog-item__excerpt"
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
        <Link className="blog-item__link hide" to={`/posts/${post.slug}`}>
          Keep Reading →
        </Link>
      </div>
    </article>
  );
}

export default BlogArchiveItem;