import React from 'react';
import Fade from 'react-reveal/Fade';
// styles
import 'src/scss/blog.scss';
// components
import BlogArchiveItem from 'src/components/BlogArchiveItem';
import SEO from 'src/components/SEO';
import SectionTrigger from 'src/components/SectionTrigger';

function BlogPostArchivePage({ data }) {
  return (
    <div>
      <SEO title="Conversation | Falkon Content" />
      <SectionTrigger
        bgColor="#0f8bbb"
        className="blog"
        textColor="#fff"
      >
        <div className="blog__container blog__container--wide">
          <header className="blog__header desktop-only">
            <h1 className="blog__heading">
              Conversation
            </h1>
          </header>
        </div>
        <div className="blog__container">
          <Fade bottom duration={1240} delay={100}>
            <header className="blog__header hide-desktop">
              <h1 className="blog__heading">Conversation</h1>
            </header>
            <div className="blog-list">
              {data.allWordpressPost.edges.map(post => (
                <BlogArchiveItem post={post.node} key={post.node.wordpress_id} />
              ))}
            </div>
          </Fade>
          <footer className="blog-footer">
            <p className="blog-footer__heading">
              Archive
            </p>
          </footer>
        </div>
      </SectionTrigger>
    </div>
  );
}

export default BlogPostArchivePage;

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          date(formatString: "MMMM DD, YYYY")
          excerpt
          featured_media {
            alt_text
            source_url
          }
          slug
          title
          wordpress_id
        }
      }
    }
  }
`